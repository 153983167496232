import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import Input from "@material-ui/core/Input";
import styles from "./bulk-update-preview.module.scss";
import { Rowprop, rowType } from "../yield-sheet/rowProp";
import { InputAdornment, InputLabel } from "@material-ui/core";
import { Search, CancelRounded } from "@material-ui/icons";
import SectionTitle from "../elements/sectionTitle";

interface State {
  hotel?: any;
  //roomTypesConfig: ReturnType<typeof getRoomTypesConfig>
  selections: {
    [roomType: string]: {
      selected: boolean;
      ratePlans: {
        [ratePlan: string]: boolean;
      };
    };
  };
  allRTSelected: boolean;
  allRPSelected: boolean;
  rowProps?: Rowprop;
  collapsed: string[];
  filtered: string[];
  filterValue: string;
}

interface Props {
  appStore: any;
  hotelId: number;
  rate: number;
  manualOnly: boolean;
  selectable?: boolean;

  onSelection?: (selections: State["selections"]) => void;
}

class BulkUpdatePreview extends Component<Props, State> {
  _isMounted = false;
  readonly state: State = {
    selections: {},
    allRTSelected: false,
    allRPSelected: false,
    collapsed: [],
    filtered: [],
    filterValue: "",
    //roomTypesConfig: [],
  };

  constructor(props: Props) {
    super(props);

    let hotel = this.props.appStore.meta.config.hotels[this.props.hotelId];
    let rowProps = this.props.appStore.meta.config.rowProps[this.props.hotelId];
    //const roomTypesConfig = getRoomTypesConfig(hotel)
    const selections: State["selections"] = {};
    let collapsed: string[] = [];
    rowProps.rtConf.forEach((roomConfig: any) => {
      collapsed.push(roomConfig.id);
      selections[roomConfig.id] = {
        selected: false,
        ratePlans: {},
      };
    });
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
    this.collapseAll = this.collapseAll.bind(this);
    this.toggleAllRP = this.toggleAllRP.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.state = {
      selections,
      hotel,
      rowProps,
      allRTSelected: false,
      allRPSelected: false,
      collapsed,
      filtered: [],
      filterValue: "",
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { hotelId, rate: baseRate, selectable } = this.props;
    const { hotel, selections, rowProps } = this.state;

    if (!baseRate || !rowProps || !(hotelId && baseRate && hotel)) {
      return <div />;
    }

    for (let i = 0; i < rowProps.lastRateIndex; i++) {
      let rowProp = rowProps.get(i);
      if (rowProp.type === rowType.roomType) {
      }
    }

    var selectedMap: any = {};
    rowProps.rtConf.forEach((rt: any) => {
      let selected = 0;
      Object.keys(selections[rt.id].ratePlans).forEach((rp: string) => {
        if (selections[rt.id].ratePlans[rp]) {
          selected++;
        }
      });
      selectedMap[rt.id] = selected;
    });

    return (
      <Fragment>
        <br />
        {selectable && (
          <Fragment>
            <SectionTitle {...{ value: "Room and rateplans selection" }} />
            <div>
              <form>
                <div style={{ display: "inline-block" }}>
                  <br />
                  {!this.props.manualOnly && (
                    <button
                      className="btn btn-primary mr-4"
                      style={{ marginBottom: "20px" }}
                      type="button"
                      hidden={!selectable}
                      onClick={() => this.toggleAllRT()}
                    >
                      {this.state.allRTSelected
                        ? "- all roomtypes"
                        : "+ all roomtypes"}{" "}
                    </button>
                  )}

                  <button
                    className="btn btn-primary mr-4"
                    style={{ marginLeft: "30px", marginBottom: "20px" }}
                    type="button"
                    hidden={!selectable}
                    onClick={() => this.toggleAllRP()}
                  >
                    {this.state.allRPSelected
                      ? "- all rateplans"
                      : "+ all rateplans"}{" "}
                  </button>
                  <button
                    className="btn btn-primary mr-4"
                    style={{ marginLeft: "30px", marginBottom: "20px" }}
                    type="button"
                    hidden={!selectable}
                    onClick={() => this.collapseAll()}
                  >
                    collapse all
                  </button>
                  <div
                    style={{ display: "inline-block", marginLeft: "30px" }}
                    className="filterSearch"
                  >
                    <InputLabel htmlFor="input-with-icon-adornment">
                      Filter rate plans
                    </InputLabel>
                    <Input
                      id="input-with-icon-adornment"
                      value={this.state.filterValue}
                      startAdornment={
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      }
                      endAdornment={
                        this.state.filterValue.length > 0 && (
                          <InputAdornment position="end">
                            <CancelRounded onClick={this.clearFilter} />
                          </InputAdornment>
                        )
                      }
                      onChange={this.applyFilter}
                      onClick={this.applyFilter}
                    />
                  </div>
                </div>
              </form>

              <table className="table table-sm w-80">
                <colgroup>
                  <col className="w-50" />
                  <col className="" />
                </colgroup>
                <tbody>
                  {rowProps.rtConf.map(
                    ({
                      ratePlans,
                      id,
                      shortDescription,
                    }: {
                      ratePlans: any;
                      id: any;
                      shortDescription: string;
                    }) => (
                      <Fragment key={id}>
                        <tr>
                          <th colSpan={3}>
                            <div style={{ position: "sticky", top: 0 }}>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id={id}
                                hidden={!selectable}
                                disabled={this.props.manualOnly}
                                checked={
                                  (selections[id] && selections[id].selected) ||
                                  false
                                }
                                onChange={() => this.toggle("roomType", id)}
                              />
                              <label className={styles.label} htmlFor={id}>
                                <button
                                  onClick={() => this.toggleCollapse(id)}
                                  className="icon-button"
                                >
                                  <ion-icon
                                    name={
                                      this.state.collapsed.indexOf(id) !== -1
                                        ? "ios-arrow-forward"
                                        : "ios-arrow-down"
                                    }
                                  />
                                </button>
                                {shortDescription}{" "}
                                {selectedMap[id] > 0 ? (
                                  <span style={{ fontWeight: "normal" }}>
                                    {" - " + selectedMap[id] + " selected "}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </th>
                        </tr>
                        {ratePlans.map((ratePlan: any) => {
                          if (
                            this.state.collapsed.indexOf(id) == -1 &&
                            this.state.filtered.indexOf(ratePlan.id) == -1 &&
                            (ratePlan.isManual ||
                              (!ratePlan.isManual && !this.props.manualOnly))
                          ) {
                            return (
                              <tr key={`${id}_${ratePlan.id}`}>
                                <td className={styles.rate_plan}>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id={`${id}_${ratePlan.id}`}
                                    hidden={!selectable}
                                    disabled={
                                      (selections[id] &&
                                        selections[id].selected) ||
                                      false
                                    }
                                    checked={
                                      (selections[id] &&
                                        selections[id].ratePlans &&
                                        selections[id].ratePlans[
                                          ratePlan.id
                                        ]) ||
                                      false
                                    }
                                    onChange={() =>
                                      this.toggle("ratePlan", id, ratePlan.id)
                                    }
                                  />
                                  <label
                                    className={styles.label}
                                    htmlFor={`${id}_${ratePlan.id}`}
                                  >
                                    {ratePlan.shortDescription}
                                    {ratePlan.isManual ? (
                                      <span className={styles.red}>
                                        {" "}
                                        - (set rate)
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </label>
                                </td>
                              </tr>
                            );
                          }
                        })}
                      </Fragment>
                    ),
                  )}
                </tbody>
              </table>
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  }
  toggleCollapse(id: any) {
    let co = this.state.collapsed;
    let index = co.indexOf(id);

    if (index == -1) {
      co.push(id);
      this.setState({ collapsed: co });
    } else {
      delete co[index];

      this.setState({ collapsed: co });
    }
  }
  collapseAll() {
    let c: string[] = [];
    this.state.rowProps!.rtConf.forEach((roomConfig: any) => {
      c.push(roomConfig.id);
    });
    this.setState({ collapsed: c });
  }
  toggleAllRT() {
    const { selections } = this.state;

    Object.keys(selections).forEach((rt) => {
      selections[rt].selected = !this.state.allRTSelected;
    });
    this.setState({ selections, allRTSelected: !this.state.allRTSelected });
    if (this.props.onSelection) {
      this.props.onSelection(selections);
    }
  }

  toggleAllRP() {
    const { selections } = this.state;

    this.state.rowProps!.rtConf.forEach((rt: any) => {
      if (!selections[rt.id].selected) {
        for (let rp of rt.ratePlans) {
          if (this.state.filtered.indexOf(rp.id) == -1) {
            let rps = selections[rt.id].ratePlans || {};
            rps[rp.id] = !this.state.allRPSelected;
            selections[rt.id].ratePlans = rps;
          }
        }
      }
    });
    this.setState({
      selections: selections,
      allRPSelected: !this.state.allRPSelected,
    });
    if (this.props.onSelection) {
      this.props.onSelection(selections);
    }
  }

  toggle(type: "roomType" | "ratePlan", roomType: string, ratePlan = "") {
    const { selections } = this.state;
    if (type === "roomType") {
      selections[roomType].selected = !selections[roomType].selected;
      if (selections[roomType].selected) {
        selections[roomType].ratePlans = {};
      }
    } else {
      selections[roomType].ratePlans[ratePlan] =
        !selections[roomType].ratePlans[ratePlan];
    }

    this.setState({ selections });
    if (this.props.onSelection) {
      this.props.onSelection(selections);
    }
  }

  clearFilter(event: any) {
    this.setState({ filtered: [], filterValue: "" });
    event.stopPropagation();
    return;
  }

  applyFilter(event: any) {
    if (!event.target.value) {
      this.setState({ filterValue: event.target.value });
      return;
    }
    let v = event.target.value.toLowerCase();
    if (v.length < 2) {
      this.setState({ filtered: [], filterValue: event.target.value });
      event.stopPropagation();
      return;
    }
    let filtered: string[] = [];
    this.state.rowProps!.rtConf.forEach((roomConfig: any) => {
      roomConfig.ratePlans.forEach((rp: any) => {
        let tokens = v.split(" ");
        for (let token of tokens) {
          if (
            !rp.shortDescription
              .replace(/[^\w\s]/gi, "")
              .toLowerCase()
              .includes(token.replace(/[^\w\s]/gi, ""))
          ) {
            filtered.push(rp.id);
          }
        }
      });
    });

    this.setState({
      filtered: filtered,
      collapsed: [],
      filterValue: event.target.value,
    });
  }
}

const BulkUpdatePreviewObserver = inject("appStore")(
  observer(BulkUpdatePreview),
);
export { BulkUpdatePreviewObserver as BulkUpdatePreview };
