import {
  Box,
  Button,
  TextField,
  Checkbox,
  InputLabel,
  Select,
  NativeSelect,
  MenuItem,
  FormControlLabel,
  Typography,
  FormControl,
  FormGroup,
  CircularProgress,
} from "@mui/material";
import { Component, Fragment } from "react";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  BulkOwsOverridePayload,
  BulkUpdatePayload,
  Dow,
  IBulkManualRateUpdate,
  IBulkRewardsUpdate,
  IManualRateUpdate,
  IRestrictionsUpdate,
} from "../../models/api.models";
import { YieldSheetService } from "../services";
import { appStore } from "../store";
import { BulkUpdatePreview } from "./bulk-update-preview";
import { restrictions } from "gn-shared";
import moment from "moment";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import LoadingButton from "@mui/lab/LoadingButton";
import Popup from "../../reusable-components/popup/popup";
import "./bulk-update.css";
import { getFieldClasses } from "../common/forms";
import { styled } from "@mui/material/styles";
import { YieldApi } from "api/yield";

interface PublishPayload {
  start: string;
  end: string;
  baseRate: number;
  weekDays: boolean[];
  owslabel: string;
}

interface Period {
  start: string;
  end: string;
  idx: number;
}

interface FormState extends PublishPayload {
  property:
    | "baseRate"
    | "owsBaseRate"
    | "setRate"
    | "APPLY_STOP_SELL"
    | "REMOVE_STOP_SELL"
    | "APPLY_CLOSED_TO_ARRIVAL"
    | "REMOVE_CLOSED_TO_ARRIVAL"
    | "APPLY_CLOSED_TO_DEPARTURE"
    | "REMOVE_CLOSED_TO_DEPARTURE"
    | "APPLY_MIN_DAYS#2"
    | "REMOVE_MIN_DAYS"
    | "OPEN_REWARDS"
    | "CLOSE_REWARDS"
    | "OVERIDE_OWS";
  allDayCheked: boolean;
}

export interface State {
  toYieldSheet: boolean;
  displayWarning: boolean;
  displaySpinner: boolean;
  restrictionSelections: any;
  allDaySelected: boolean;
  hotel: any;
  periods: Period[];
  defaultOwsLabel: string;
  popupStatus: boolean;
  weekDays: boolean[];
  property: string;
  baseRate: number;
  errors: { [key: string]: string };
  owslabel: string;
  touched: { [key: string]: boolean };
  navigateToYieldSheet: boolean;
  level: string;
}

interface bulkUpdateProps {
  activeHotelId: number;
  isPopupOpen: boolean;
  popupState: (state: boolean) => void;
}

const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const DatePickerWrapper = styled("div")({
  position: "relative",
  "& .MuiInputLabel-root": {
    position: "absolute",
    backgroundColor: "#fff",
    padding: "0 4px",
    top: "-8px",
    left: "5rem",
    fontSize: "0.75rem",
    zIndex: 1,
    color: "rgba(0, 0, 0, 0.6)",
  },
  "& .MuiInputLabel-root.to-label": {
    left: "2.5rem",
  },
});

class BulkUpdate extends Component<bulkUpdateProps, State> {
  readonly state: State = {
    hotel: {},
    toYieldSheet: false,
    displayWarning: false,
    displaySpinner: false,
    restrictionSelections: {},
    allDaySelected: true,
    defaultOwsLabel: "Default",
    popupStatus: false,

    periods: [
      {
        idx: 0,
        start: moment().format("YYYY-MM-DD"),
        end: moment().format("YYYY-MM-DD"),
      },
    ],
    weekDays: [true, true, true, true, true, true, true],
    property: "baseRate",
    baseRate: 999,
    errors: {},
    owslabel: "Default",
    touched: {},
    navigateToYieldSheet: false,
    level: "baseRate",
  };

  constructor(props: any) {
    super(props);

    this.state.hotel = appStore.meta.config.hotels[this.props.activeHotelId];
    this.state.baseRate =
      appStore.meta.config.hotels[this.props.activeHotelId].meta.defaultBar;
    this.applyToYieldSheet = this.applyToYieldSheet.bind(this);

    if (typeof this.state.hotel.cm.channelConfig.ows.label !== "undefined") {
      this.state.defaultOwsLabel = this.state.hotel.cm.channelConfig.ows.label;
    }
    this.state.popupStatus = this.props.isPopupOpen;
  }

  addPeriod = () => {
    let periods = this.state.periods;
    periods.push({
      idx: periods.length,
      start: moment().format("YYYY-MM-DD"),
      end: moment().format("YYYY-MM-DD"),
    });
    this.setState({ periods });
  };

  removePeriod = () => {
    let periods = this.state.periods;
    periods.pop();
    this.setState({ periods });
  };

  applyToYieldSheet(data: any) {
    this.setState({ navigateToYieldSheet: true });
    if (data.baseRate <= 0) {
      toast("Please check the highlited field", { type: "error" });
      return;
    } else {
      let formDataValues = data;
      this.setState({ displaySpinner: true });
      let payloads;
      let promises;
      switch (formDataValues.property) {
        case "OVERIDE_OWS":
          payloads = this.prepareOverideOwsPayload(formDataValues);
          promises = payloads.map((payload) =>
            YieldApi.bulkUpdateOwsOverride(payload),
          );
          Promise.all(promises)
            .then(() => {
              this.setState({ displayWarning: true, displaySpinner: false });
            })
            .catch((err: any) => {
              console.error(err);
              this.setState({ displaySpinner: false });
              toast.error("Error while updating OWS overrides");
            });
          break;
        case "baseRate":
          payloads = this.prepareBaseRatePayload(formDataValues);
          promises = payloads.map((payload) =>
            YieldSheetService.revanista.yieldsheet.days.bulkUpdateRates(
              payload,
              this.props,
            ),
          );
          Promise.all(promises)
            .then(() => {
              this.setState({ displayWarning: true, displaySpinner: false });
            })
            .catch((err: any) => {
              console.error(err);
              this.setState({ displaySpinner: false });
              toast.error("Error while updating rates");
            });
          break;
        case "owsBaseRate":
          console.log("hp");
          payloads = this.prepareOWSBaseRatePayload(formDataValues);
          promises = payloads.map((payload) =>
            YieldSheetService.revanista.yieldsheet.days.bulkUpdateRates(
              payload,
              this.props,
            ),
          );
          Promise.all(promises)
            .then(() => {
              this.setState({ displayWarning: true, displaySpinner: false });
            })
            .catch((err: any) => {
              console.error(err);
              this.setState({ displaySpinner: false });
              toast.error("Error while updating rates");
            });
          break;
        case "setRate":
          payloads = this.prepareSetRatePayload(formDataValues);
          promises = payloads.map((payload) =>
            YieldSheetService.revanista.yieldsheet.days.bulkUpdateManualRates(
              payload,
              this.props,
            ),
          );
          Promise.all(promises)
            .then(() => {
              this.setState({ displayWarning: true, displaySpinner: false });
            })
            .catch((err: any) => {
              console.error(err);
              this.setState({ displaySpinner: false });
              toast.error("Error while updating rates");
            });
          break;
        case "OPEN_REWARDS":
        case "CLOSE_REWARDS":
          payloads = this.prepareRewardsPayload(formDataValues);
          promises = payloads.map((payload) =>
            YieldSheetService.revanista.yieldsheet.days.bulkUpdateRewards(
              payload,
              this.props,
            ),
          );
          Promise.all(promises)
            .then(() => {
              this.setState({ displayWarning: true, displaySpinner: false });
            })
            .catch((err: any) => {
              console.error(err);
              this.setState({ displaySpinner: false });
              toast.error("Error while updating restrictions");
            });

          break;
        default:
          this.prepareRestrictionsPayload(formDataValues).then((payloads) => {
            promises = payloads.map((payload) =>
              YieldApi.bulkUpdateRestrictions(payload),
            );
            Promise.all(promises)
              .then(() => {
                this.setState({ displayWarning: true, displaySpinner: false });
              })
              .catch((err: any) => {
                console.error(err);
                this.setState({ displaySpinner: false });
                toast("Error while updating restrictions", { type: "error" });
              });
          });
      }
    }
  }

  discardAndExit() {
    this.props.popupState(false);
  }

  handleInputChange = (event: any, field: string, formState: any) => {
    const { value } = event.target;
    this.setState({ baseRate: value });
    this.validate(value, formState);
  };

  setStartDate = (date: Date | null, id: any) => {
    if (date) {
      this.setState((prevState) => {
        const periods = prevState.periods.map((period) => {
          if (period.idx === id.idx) {
            const newStartDate = moment(date).format("YYYY-MM-DD");
            // If the new start date is after the current end date, update the end date
            const currentEndDate = moment(period.end);
            if (moment(date).isAfter(currentEndDate)) {
              return {
                ...period,
                start: newStartDate,
                end: newStartDate,
              };
            }
            return { ...period, start: newStartDate };
          }
          return period;
        });
        return { periods };
      });
    }
  };

  handleWeekDayChange = (index: any) => {
    this.setState((prevState) => {
      const newWeekDays = [...prevState.weekDays];
      newWeekDays[index] = !newWeekDays[index];
      return { weekDays: newWeekDays };
    });
  };

  setEndDate = (date: Date | null, id: any) => {
    if (date) {
      this.setState((prevState) => ({
        periods: prevState.periods.map((period) => {
          if (period.idx === id.idx) {
            const newEndDate = moment(date).format("YYYY-MM-DD");
            return { ...period, end: newEndDate };
          }
          return period;
        }),
      }));
    }
  };

  validate = (value: any, formState: any) => {
    let error;
    if (!value) {
      error = "Required";
    } else if (value <= 0) {
      error = "Must be positive";
    }
    return error;
  };

  handleNativeSelect = (event: any) => {
    const rateLabel = event.target.value;
    getRateLabel(rateLabel);
    this.setState({ property: rateLabel });
    this.setState({ level: event.target.value });
  };

  render() {
    const { periods } = this.state;
    if (this.state.toYieldSheet) {
      return <Navigate to={`/${this.props.activeHotelId}`} />;
    }

    let owsOverrideOptions: any = null;

    if (this.state.hotel?.cm?.channelConfig?.ows?.SeasonnalConfig?.formulas) {
      owsOverrideOptions =
        this.state.hotel.cm.channelConfig.ows.SeasonnalConfig.formulas.map(
          (item: any) => (
            <option key={item.label} value={item.label}>
              {item.label}
            </option>
          ),
        );
    }

    return (
      <>
        <Popup
          isPopupOpen={this.state.displayWarning}
          title="All changes have been applied to the Yield Sheet"
          content={[
            "Please return to the Yield Sheet and publish your changes to make them live.",
          ]}
          action={() => {
            this.setState({ displayWarning: false });
          }}
          actionName="Ok"
          cancelAction={() => {
            this.setState({ displayWarning: false });
            this.props.popupState(false);
          }}
          cancelName="Back to yield sheet"
        />
        <Dialog
          open={this.props.isPopupOpen}
          onClose={() => this.props.popupState(false)}
          maxWidth="md"
          fullWidth
          disableEnforceFocus // fix focustrap error coming from mui v4 vs v5 clash
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{ backgroundColor: "black", height: "61px" }}
          >
            <span
              style={{
                color: "white",
              }}
            >
              Bulk Update
            </span>
          </DialogTitle>

          <Box
            component="div"
            className="formikClass"
            sx={{
              padding: 2,
              overflowX: "hidden",
              width: "100%",
            }}
          >
            <Box className="row" sx={{ paddingRight: "1rem" }}>
              <Box>
                {this.state.periods.map((period: Period) => (
                  <Box
                    key={period.idx}
                    sx={{
                      width: "100%",
                      marginTop: "2vh",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        marginBottom: "1rem",
                        marginLeft: "3rem",
                        marginRight: "3rem",
                        width: "100%",
                      }}
                    >
                      <DatePickerWrapper>
                        <InputLabel
                          className="to-label"
                          sx={{ paddingLeft: "1rem" }}
                        >
                          From
                        </InputLabel>
                        <DatePicker
                          selected={moment(period.start).toDate()}
                          onChange={(date: Date | null) => {
                            this.setStartDate(date, period);
                          }}
                          dateFormat="dd/MM/yyyy"
                          className="dateStartandEnd to-date"
                          minDate={new Date()}
                          popperPlacement="bottom"
                        />
                      </DatePickerWrapper>
                      <DatePickerWrapper>
                        <InputLabel
                          className="to-label"
                          sx={{ paddingLeft: "1rem" }}
                        >
                          To
                        </InputLabel>
                        <DatePicker
                          selected={moment(period.end).toDate()}
                          onChange={(date: Date | null) =>
                            this.setEndDate(date, period)
                          }
                          dateFormat="dd/MM/yyyy"
                          className="dateStartandEnd to-date"
                          minDate={moment(period.start).toDate()}
                          popperPlacement="bottom"
                        />
                      </DatePickerWrapper>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
            <Box sx={{ marginTop: "1rem", paddingRight: "1rem" }}>
              <Box
                className="form-group row"
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    marginRight: "1rem",
                    marginLeft: "5rem",
                  }}
                >
                  On
                </Typography>
                {days.map((day, dayIdx) => (
                  <FormControlLabel
                    key={day}
                    control={
                      <Checkbox
                        checked={this.state.weekDays[dayIdx]}
                        onChange={() => this.handleWeekDayChange(dayIdx)}
                        name={day}
                        color="primary"
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 15 } }}
                      />
                    }
                    label={day}
                  />
                ))}
              </Box>
            </Box>
            <Box
              className="row align-items-center"
              display="flex"
              alignItems="center"
            >
              <Box
                className="col col-sm-3"
                marginLeft="5rem"
                marginRight="5rem"
                display="flex"
                alignItems="center"
                gap={1}
              >
                <Button onClick={this.addPeriod}>
                  <AddIcon />
                </Button>
                {this.state.periods.length > 1 && (
                  <Button onClick={this.removePeriod}>
                    <RemoveIcon />
                  </Button>
                )}
              </Box>
            </Box>
            <Box
              className="row align-items-center"
              sx={{
                marginTop: "1rem",
                marginLeft: "4rem",
                marginRight: "4rem",
              }}
            >
              <Box>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className="form-group"
                >
                  <TextField
                    className="UpdateClass"
                    id="property-label"
                    style={{
                      width: "3rem !important",
                      backgroundColor:
                        appStore.meta._user.permissions.admin[0] !== "*"
                          ? "#D3D3D3"
                          : "none",
                    }}
                    fullWidth
                    select
                    label="Update"
                    variant="outlined"
                    name="level"
                    value={this.state.level}
                    onChange={(e) => this.handleNativeSelect(e)}
                  >
                    <MenuItem value="baseRate">Base Rate</MenuItem>
                    {hasOWSBRRow(this.state.hotel) && (
                      <MenuItem value="owsBaseRate">OWS Base Rate</MenuItem>
                    )}

                    <MenuItem value="OVERIDE_OWS">OWS Override</MenuItem>

                    <MenuItem value="setRate">Set Rate</MenuItem>
                    <MenuItem value="APPLY_STOP_SELL">+ Stop Sell</MenuItem>
                    <MenuItem value="REMOVE_STOP_SELL">- Stop Sell</MenuItem>
                    <MenuItem value="APPLY_MIN_DAYS#2">+ Min LOS 2</MenuItem>
                    <MenuItem value="REMOVE_MIN_DAYS">- Min LOS</MenuItem>

                    {this.state.hotel.meta.hasRewards && [
                      <MenuItem key="CLOSE_REWARDS" value="CLOSE_REWARDS">
                        Close Rewards
                      </MenuItem>,
                      <MenuItem key="OPEN_REWARDS" value="OPEN_REWARDS">
                        Open Rewards
                      </MenuItem>,
                    ]}
                    <MenuItem value="APPLY_CLOSED_TO_ARRIVAL">
                      + Closed To Arrival
                    </MenuItem>

                    <MenuItem value="REMOVE_CLOSED_TO_ARRIVAL">
                      - Closed To Arrival
                    </MenuItem>
                    <MenuItem value="APPLY_CLOSED_TO_DEPARTURE">
                      + Closed To Departure
                    </MenuItem>
                    <MenuItem value="REMOVE_CLOSED_TO_DEPARTURE">
                      - Closed To Departure
                    </MenuItem>
                  </TextField>
                </FormControl>
              </Box>
              <Box
                sx={{
                  display:
                    this.state.property === "baseRate" ||
                    this.state.property === "setRate" ||
                    this.state.property === "owsBaseRate"
                      ? "block"
                      : "none",
                }}
                className="col col-sm-3"
              >
                <div className="bulkUpdateTop">
                  <TextField
                    id="baseRate"
                    name="baseRate"
                    label={getRateLabel(this.state.property)}
                    variant="outlined"
                    style={{
                      width: "3rem !important",
                      backgroundColor:
                        appStore.meta._user.permissions.admin[0] !== "*"
                          ? "#D3D3D3"
                          : "transparent",
                    }}
                    type="number"
                    placeholder=""
                    value={this.state.baseRate}
                    InputProps={{ inputProps: { step: 1 } }}
                    onChange={(e) =>
                      this.handleInputChange(e, "baseRate", this.state)
                    }
                    className={getFieldClasses(this.state, "baseRate")}
                    error={!!this.state.errors.baseRate}
                    helperText={this.state.errors.baseRate || ""}
                  />
                </div>
                <div className="bulkValidation">
                  {this.state.errors.baseRate}
                </div>
              </Box>
              <Box className="col col-sm-3">
                <div hidden={this.state.property !== "OVERIDE_OWS"}>
                  <TextField
                    id="property-label"
                    style={{
                      width: "3rem !important",
                      backgroundColor:
                        appStore.meta._user.permissions.admin[0] !== "*"
                          ? "#D3D3D3"
                          : "transparent",
                    }}
                    fullWidth
                    select
                    name="owslabel"
                    label="Label"
                    variant="outlined"
                    value={this.state.owslabel}
                    onChange={(e) =>
                      this.setState({ owslabel: e.target.value })
                    }
                  >
                    <MenuItem value={this.state.defaultOwsLabel}>
                      {this.state.defaultOwsLabel}
                    </MenuItem>

                    {owsOverrideOptions && Array.isArray(owsOverrideOptions)
                      ? owsOverrideOptions.map((option, index) => (
                          <MenuItem key={index} value={option.key}>
                            {option.key}
                          </MenuItem>
                        ))
                      : null}
                  </TextField>
                </div>
              </Box>
            </Box>
            <div className="detailedUpdate">
              <BulkUpdatePreview
                hotelId={this.state.hotel.hotelId}
                rate={
                  !(
                    this.state.property == "baseRate" ||
                    this.state.property == "owsBaseRate" ||
                    this.state.property == "CLOSE_REWARDS" ||
                    this.state.property == "OPEN_REWARDS" ||
                    this.state.property == "OVERIDE_OWS"
                  )
                    ? 100
                    : this.state.baseRate
                }
                selectable={
                  this.state.property !== "baseRate" &&
                  this.state.property !== "owsBaseRate" &&
                  this.state.property !== "CLOSE_REWARDS" &&
                  this.state.property !== "OPEN_REWARDS" &&
                  this.state.property !== "OVERIDE_OWS"
                }
                manualOnly={this.state.property == "setRate"}
                onSelection={(selections) =>
                  this.setState({ restrictionSelections: selections })
                }
                appStore={appStore}
              />
            </div>
            <div className="col d-flex justify-content-end mt-4">
              <Button
                onClick={() => this.discardAndExit()}
                color="secondary"
                variant="contained"
                style={{
                  backgroundColor: "#808080",
                  color: "#ffffff",
                  marginRight: "10px",
                  marginTop: "10px",
                }}
              >
                Discard
              </Button>

              <LoadingButton
                onClick={() => {
                  this.applyToYieldSheet(this.state);
                }}
                loading={this.state.displaySpinner}
                loadingIndicator={
                  <CircularProgress
                    style={{ color: "#000000", opacity: 1 }}
                    size={24}
                  />
                }
                color="primary"
                style={{
                  backgroundColor: "#ce0e29",
                  color: "#ffffff",
                  marginRight: "10px",
                  marginTop: "10px",
                }}
                variant="contained"
              >
                Apply
              </LoadingButton>
            </div>
          </Box>
        </Dialog>
      </>
    );
  }

  private prepareRewardsPayload(data: FormState): IBulkRewardsUpdate[] {
    let hotelId = this.state.hotel.hotelId;
    const dow: Dow = days.reduce(
      (acc, day, idx) => ({ ...acc, [day]: data.weekDays[idx] }),
      {},
    );

    let res = this.state.periods.map((p) => {
      return {
        start: p.start,
        end: p.end,
        hotelId,
        dow,
        active: data.property.indexOf("OPEN") != -1,
      };
    });
    return res;
  }
  private prepareOverideOwsPayload(
    data: PublishPayload,
  ): BulkOwsOverridePayload[] {
    let hotelId = this.state.hotel.hotelId;
    const dow: Dow = days.reduce(
      (acc, day, idx) => ({ ...acc, [day]: data.weekDays[idx] }),
      {},
    );

    let res = this.state.periods.map((p) => {
      return {
        ...data,
        start: p.start,
        end: p.end,
        baseRate: data.baseRate,
        dow,
        hotelId,
        label: data.owslabel,
      };
    });
    return res;
  }
  private prepareBaseRatePayload(data: PublishPayload): BulkUpdatePayload[] {
    let hotelId = this.state.hotel.hotelId;
    const dow: Dow = days.reduce(
      (acc, day, idx) => ({ ...acc, [day]: data.weekDays[idx] }),
      {},
    );

    let res = this.state.periods.map((p) => {
      return {
        ...data,
        start: p.start,
        end: p.end,
        baseRate: data.baseRate,
        dow,
        hotelId,
      };
    });
    return res;
  }
  private prepareOWSBaseRatePayload(data: PublishPayload): BulkUpdatePayload[] {
    let hotelId = this.state.hotel.hotelId;
    const dow: Dow = days.reduce(
      (acc, day, idx) => ({ ...acc, [day]: data.weekDays[idx] }),
      {},
    );

    let res = this.state.periods.map((p) => {
      return {
        weekDays: data.weekDays,
        baseRate: 0,
        start: p.start,
        end: p.end,
        owsBaseRate: data.baseRate,
        dow,
        hotelId,
      };
    });
    return res;
  }
  private prepareSetRatePayload(data: FormState): IBulkManualRateUpdate[] {
    let hotel = this.state.hotel;
    let hotelId = hotel.hotelId;
    const dow: Dow = days.reduce(
      (acc, day, idx) => ({ ...acc, [day]: data.weekDays[idx] }),
      {},
    );

    let results = this.state.periods.map((p) => {
      let v: IManualRateUpdate[] = [];
      return {
        dow,
        updates: v,
        hotelId,
        start: p.start,
        end: p.end,
      };
    });

    console.log(this.state.restrictionSelections);
    results.forEach((res) => {
      Object.keys(this.state.restrictionSelections)
        .map((roomtype) => {
          let i: IManualRateUpdate[] = [];
          let rps = Object.keys(
            this.state.restrictionSelections[roomtype].ratePlans,
          );
          rps.forEach((rp: string) => {
            i.push({
              hotelId,
              start: res.start,
              end: res.end,
              rate: data.baseRate * 100,
              invTypeCode: roomtype,
              ratePlanID: rp,
            });
          });
          return i;
        })
        .forEach((value) => {
          res.updates.push(...value);
        });
    });
    return results;
  }

  private prepareRestrictionsPayload(
    data: FormState,
  ): Promise<IRestrictionsUpdate[]> {
    return new Promise((resolve, reject) => {
      let hotel = this.state.hotel;
      let hotelId = hotel.hotelId;
      const actionName: "apply" | "remove" = !data.property.indexOf("APPLY_")
        ? "apply"
        : "remove";
      const daycount = data.property.indexOf("#")
        ? data.property.split("#")[1]
        : 0;
      const restrictionKey = data.property
        .replace(/^[A-Z]*_/, "")
        .split("#")[0] as RestrictionTypeKeys;
      const restriction: RestrictionToApply = {
        type: restrictions.TYPE[restrictionKey],
        dayCount: Number(daycount),
      };
      const dow: Dow = days.reduce(
        (acc, day, idx) => ({ ...acc, [day]: data.weekDays[idx] }),
        {},
      );
      const makeDayObj = (roomType: string) => ({
        restrictions: {
          [roomType]: {
            ratePlans: Object.keys(hotel.ratePlans).reduce(
              (acc, ratePlan) => ({ ...acc, [ratePlan]: {} }),
              {},
            ),
          },
        },
      });
      const roomTypeRestrictions = Object.keys(this.state.restrictionSelections)
        .filter(
          (roomType) => this.state.restrictionSelections[roomType].selected,
        )
        .map(async (roomType) => {
          let obj = makeDayObj(roomType);
          if (actionName === "apply") {
            obj = await restrictions.apply(obj, { ...restriction, roomType });
          } else {
            obj = await restrictions.remove(hotel.meta.globalRestriction, obj, {
              ...restriction,
              roomType,
            });
          }

          return obj;
        });
      const ratePlanRestrictions = Object.keys(this.state.restrictionSelections)
        .filter((roomType) => {
          const roomTypeData = this.state.restrictionSelections[roomType];
          return (
            !roomTypeData.selected &&
            Object.keys(roomTypeData.ratePlans).some(
              (ratePlan) => roomTypeData.ratePlans[ratePlan],
            )
          );
        })
        .map(async (roomType) => {
          let obj = makeDayObj(roomType);
          const roomTypeData = this.state.restrictionSelections[roomType];
          let rps = Object.keys(roomTypeData.ratePlans)
            .filter((ratePlan) => roomTypeData.ratePlans[ratePlan])
            .filter(
              (ratePlan) =>
                !hotel.ratePlans[ratePlan].restrictionExclusions ||
                hotel.ratePlans[ratePlan].restrictionExclusions.indexOf(
                  restriction.type,
                ) == -1,
            );
          for (let ratePlan of rps) {
            if (actionName === "apply") {
              obj = await restrictions.apply(obj, {
                ...restriction,
                roomType,
                ratePlan,
              });
            } else {
              obj = await restrictions.remove(
                hotel.meta.globalRestriction,
                obj,
                { ...restriction, roomType, ratePlan },
              );
            }
          }
          return obj;
        });

      Promise.all(ratePlanRestrictions).then((v) => {
        Promise.all(roomTypeRestrictions).then((w) => {
          const allRoomTypeRestrictions = Object.assign(
            {},
            ...[...v, ...w].map((o) => o.restrictions),
          );
          let result = this.state.periods.map((p) => {
            return {
              start: p.start,
              end: p.end,
              restrictions: allRoomTypeRestrictions,
              dow,
              hotelId,
            };
          });

          resolve(result);
        });
      });
    });
  }
}
function hasOWSBRRow(hotel: any) {
  return hotel.cm.channelConfig && hotel.cm.channelConfig.ows;
}

export function getRateLabel(input: string) {
  switch (input) {
    case "baseRate":
      return "Base Rate";
    case "owsBaseRate":
      return "OWS Base Rate";
    default:
      return "Set Rate";
  }
}

export default BulkUpdate;
